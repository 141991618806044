export const FETCH_PROPERTY_PREFERENCE = 'FETCH_PROPERTY_PREFERENCE';
export const FETCH_PROPERTY_PREFERENCE_SUCCESS = 'FETCH_PROPERTY_PREFERENCE_SUCCESS';
export const FETCH_PROPERTY_PREFERENCE_FAIL = 'FETCH_PROPERTY_PREFERENCE_FAIL';
export const UPDATE_PROPERTY_PREFERENCE = 'UPDATE_PROPERTY_PREFERENCE';

export const fetchPropertyPreference = () => ({
  type: FETCH_PROPERTY_PREFERENCE
});

export const fetchPropertyPreferenceSuccess = (propPreference) => ({
  type: FETCH_PROPERTY_PREFERENCE_SUCCESS,
  payload: propPreference
});

export const fetchPropertyPreferenceFail = () => ({
  type: FETCH_PROPERTY_PREFERENCE_FAIL
});

export const updateReduxPropertyPreference = (propPreference) => ({
  type: UPDATE_PROPERTY_PREFERENCE,
  payload: propPreference
});