const Reducer = (state, action) => {
    switch (action.type) {
        case "FORM_REFRESH":
            return {
                ...state,
                loading: false,
                error: false,
                success: false,
                data: false,
                step: false,
                message: "",
            }
        case "FORM_LOADING":
            return {
                ...state,
                loading: true,
                error: false,
                success: false,
                message: "",
            }

        case "FORM_POST_ERROR":
            return {
                ...state,
                loading: false,
                error: true,
                success: false,
                data: false,
                message: action.payload.message,
            }
        case "FORM_POST_SUCCESS":

            let newState = {
                ...state,
                loading: false,
                error: false,
                success: true,
                data: action?.payload?.data?.data,
                step: action?.payload?.step,
                message: (action?.payload?.message || "Form Post Successfully."),
                actionData: null,
            }

            if (action?.payload?.postActionData) {
                newState = {
                    ...newState,
                    ...action.payload.postActionData
                }
            }

            return newState;

        case "GET_AVAILABILITY_SLOTS":
            return {
                ...state,
                loading: false,
                error: false,
                preLoaded: true,
                slots: action?.payload?.data
            }
        case "GET_AVAILABILITY_SLOTS_BY_POSTCODE":
            return {
                ...state,
                loading: false,
                error: false,
                preLoaded: true,
                slots: action?.payload?.data
            }
        case "CHANGE_FORM_STATE":
            return {
                ...state,
                ...action.payload,
            }
        case "GET_ACTION_ALLOWED":
            return {
                ...state,
                loading: false,
                error: false,
                allowedActions: action?.payload?.data,
                message: '',
            }
        case "GET_TOKEN_VALUES":
            return {
                ...state,
                loading: false,
                error: false,
                actionData:action.payload?.data?.data,
                message: '',
            }
            case "GET_TOKEN_EXPIRED":
                return {
                    ...state,
                    loading: false,
                    error: true,
                    actionData: '',
                    message: 'The Link You Followed Has Expired. Please visit your Dashboard to further action.',
                }

        default:
            return state
    }
}

export default Reducer
