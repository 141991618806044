import React from "react";

import theme from "theme";

import Root from './components/Root';

import { AuthStoreProvider } from '@myaccountServices';

import { Provider } from 'react-redux';
import store from './redux/store';

export const wrapRootElement = ({element}) => (
    <Provider store={store}>
    <Root theme={theme}>
            <AuthStoreProvider>
                {element}
            </AuthStoreProvider>
        <div id="modal-portal"></div>
    </Root>
    </Provider>
);
