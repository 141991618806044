import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import Reducers from '../reducers';

const composeEnhancers = compose;

const log = (store) => (next) => (action) => {
    return next(action)
}

const store = createStore(
    Reducers,
    // applyMiddleware(log, thunk),
    composeEnhancers(applyMiddleware(log, thunk))
)

export default store;