export const FETCH_USER_PROFILE = 'FETCH_USER_PROFILE';
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS';
export const FETCH_USER_PROFILE_FAIL = 'FETCH_USER_PROFILE_FAIL';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_PROFILE_STATE = 'UPDATE_USER_PROFILE_STATE';
export const RESET_USER_PROFILE = 'RESET_USER_PROFILE';

export const fetchUserProfile = () => ({
  type: FETCH_USER_PROFILE
});

export const fetchUserProfileSuccess = (profileData) => ({
  type: FETCH_USER_PROFILE_SUCCESS,
  payload: profileData
});

export const fetchUserProfileFail = () => ({
  type: FETCH_USER_PROFILE_FAIL
});

export const updateUserProfile = (profileData) => ({
  type: UPDATE_USER_PROFILE,
  payload: profileData
});

export const updateProfileState = (profileData) => ({
  type: UPDATE_USER_PROFILE_STATE,
  payload: profileData
});

export const resetUserProfile = () => ({
  type: RESET_USER_PROFILE
});
