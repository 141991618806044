import { get } from 'lodash';
import Cookies from "universal-cookie";
import { getUserPicture } from '../../services/store/utils';
import { FETCH_USER_PROFILE, FETCH_USER_PROFILE_FAIL, FETCH_USER_PROFILE_SUCCESS, UPDATE_USER_PROFILE, RESET_USER_PROFILE, UPDATE_USER_PROFILE_STATE } from '../actions/profile';
const cookies = new Cookies();

export const initialState = {
    success: false,
    loading: false,
    preference_success: false,
    complete_profile_success: false,
    error: false,
    profile: {},
    user: cookies.get("suser"),
    message: '',
    status: ''
};

export default function (state = initialState, { type, payload }) {
    switch(type) {
        case FETCH_USER_PROFILE:
            return {
                ...state,
                success: false,
                error: false
            }
        case FETCH_USER_PROFILE_SUCCESS:
            let userData = payload.data;
            return {
                ...state,
                jwt: userData.jwt,
                user: {
                    ...userData.user,
                    name: userData.user.forename || userData.user.username || userData.user.email,
                    uid: userData.user.id,
                    contact_id: get(userData.user, 'contact_id[0]', false),
                    picture: getUserPicture(userData.user)
                },
                loading: false,
                success: true,
                error: false,
                status: payload.status,
                message: ''
            }
        case UPDATE_USER_PROFILE:
            let data = payload.data;
            return {
                ...state,
                success: true,
                loading: false,
                preference_success: false,
                complete_profile_success: false,
                error: false,
                profile: data,
                user: {
                    ...data.user,
                    name: data.forename || data.username || data.email,
                    uid: data.id,
                    contact_id: get(data, 'contact_id[0]', false),
                    picture: getUserPicture(data.user)
                },
                message: payload?.message,
                status: payload.status
            }
        case UPDATE_USER_PROFILE_STATE:
            let user = state.user;
            let profile = state.profile;
            return {
                ...state,
                user: {
                    ...user,
                    ...payload
                },
                profile: {
                    ...profile,
                    ...payload
                }
            }
        case FETCH_USER_PROFILE_FAIL:
            return {
                ...state,
                success: false,
                error: true,
                message: "Something went wrong. Please try again!"
            }

        case RESET_USER_PROFILE:
            return {
                ...state,
                profile: {}
            }

        default:
            return state;
    }
}
