import React from 'react';
import { get } from 'lodash';
import AppFeaturesContext from "./AppFeaturesContext"
import theme from 'theme';
import uniq from "lodash/uniq"
import { MYACCOUNT_LIGHT } from "../constants/urls"
import LITE_PRESET from "../../features/lite.preset"
import ENTERPRISE_PRESET from "../../features/enterprise.preset"

const defaultFeatures = MYACCOUNT_LIGHT ? LITE_PRESET : ENTERPRISE_PRESET;

const AppFeaturesContextProvider = ({children}) => {
    const include = theme.subthemes.appFeatures?.include || [];
    const exclude = theme.subthemes.appFeatures?.exclude || [];

    const allIncluded = React.useMemo(() => {
        return uniq(include.concat(defaultFeatures));
    }, [include]);
    const appFeatures = allIncluded.filter(feature => !exclude.find(f => f === feature));
    const allFeaturesEnabled = get(theme, 'subthemes.appFeatures.allFeaturesEnabled', false);

    return (
        <AppFeaturesContext.Provider value={{
            appFeatures,
            allFeaturesEnabled,
        }}>
            {children}
        </AppFeaturesContext.Provider>
    );
};

export default AppFeaturesContextProvider;
