import { FETCH_PROPERTY_PREFERENCE, FETCH_PROPERTY_PREFERENCE_SUCCESS, FETCH_PROPERTY_PREFERENCE_FAIL, UPDATE_PROPERTY_PREFERENCE } from '../actions/propPreference';

const initialState = {
    showModal: false
}

export default function(state = initialState, { type, payload }) {
    switch(type) {
        case FETCH_PROPERTY_PREFERENCE:
        case FETCH_PROPERTY_PREFERENCE_SUCCESS:
        case FETCH_PROPERTY_PREFERENCE_FAIL:
            return {
                ...state
            }
        case UPDATE_PROPERTY_PREFERENCE:
            return {
                ...state,
                showModal: payload
            }
        default:
            return state;
    }
}